// import { captureRemixErrorBoundaryError } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useRouteError,
  // useRouteError,
} from '@remix-run/react';
import '@shopify/polaris/build/esm/styles.css';
import './components/globalstyles.css';

import { useChangeLanguage } from "remix-i18next/react";
import { useTranslation } from "react-i18next";
import i18next from "~/i18next.server";
// import GlobalLoader from './components/GlobalLoader';

export const config = {
  memory: 1024,
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  // captureRemixErrorBoundaryError(error);
  return <div>Something went wrong {JSON.stringify(error)}</div>;
  // return <div>Something went wrong</div>;
};

export let handle = {
	// In the handle export, we can add a i18n key with namespaces our route
	// will need to load. This key can be a single string or an array of strings.
	// TIP: In most cases, you should set this to your defaultNS from your i18n config
	// or if you did not set one, set it to the i18next default namespace "translation"
	i18n: "common",
};

export async function loader({ request }: { request: Request }) {
  let locale = await i18next.getLocale(request);
  return json({
    ENV: {
      SENTRY_DSN: process.env.SENTRY_DSN,
      ENVIRONMENT: process.env.ENVIRONMENT,
    },
    locale,
  });
}

export default function App() {
  const data = useLoaderData<typeof loader>();

  let { i18n } = useTranslation();
	useChangeLanguage(data.locale);

  return (
    <html lang={data.locale} dir={i18n.dir()}>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width,initial-scale=1' />
        <meta name="shopify-debug" content="web-vitals" />
        <link rel='preconnect' href='https://cdn.shopify.com/' />
        <link
          rel='stylesheet'
          href='https://cdn.shopify.com/static/fonts/inter/v4/styles.css'
        />
        <Meta />
        <Links />
      </head>
      <body>
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        {/* <GlobalLoader /> */}
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}